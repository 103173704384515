<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <Header />
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <CoverImage />
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class=" align-items-center auth-bg px-2 p-lg-5"
      >
        <div class="row">
          <div class=" mx-auto col-12 margin-top-icon-sm">
            <MediaIcon />
          </div>
          <div class="px-xl-2 mx-auto col-12 text-margin-top-sm">
            <b-card-title class="mb-1">
              New Password
            </b-card-title>

            <b-card-text>
              <validation-observer
                ref="passwordReset"
                v-slot="{ invalid }"
              >
                <b-form
                  method="POST"
                  class="auth-reset-password-form mt-2"
                  @submit.prevent="submitPasswordReset"
                >

                  <!-- password -->
                  <b-form-group
                    label="New Password"
                    label-for="reset-password-new"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      vid="Password"
                      rules="required|password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="reset-password-new"
                          v-model="password"
                          :type="password1FieldType"
                          :state="errors.length > 0 ? false:null"
                          class="form-control-merge"
                          name="reset-password-new"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="password1ToggleIcon"
                            @click="togglePassword1Visibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- confirm password -->
                  <b-form-group
                    label-for="reset-password-confirm"
                    label="Confirm Password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Confirm Password"
                      rules="required|confirmed:Password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="reset-password-confirm"
                          v-model="confirmPassword"
                          :type="password2FieldType"
                          class="form-control-merge"
                          :state="errors.length > 0 ? false:null"
                          name="reset-password-confirm"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="password2ToggleIcon"
                            @click="togglePassword2Visibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- submit button -->
                  <b-button
                    block
                    type="submit"
                    variant="primary"
                    :disabled="invalid"
                  >
                    Set New Password
                  </b-button>
                </b-form>
              </validation-observer>
            </b-card-text>
          </div>
        </div>
      </b-col>
      <!-- /Forgot password-->

      <!-- /footer-->
      <b-col
        lg="12"
        class="mt-auto"
      >
        <Footer />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BCardText, BCardTitle, BCol, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BRow,
} from 'bootstrap-vue'
import MediaIcon from '@core/components/landding-page/MediaIcon.vue'
import Footer from '@core/components/landding-page/Footer.vue'
import CoverImage from '@core/components/landding-page/CoverImage.vue'
import Header from '@core/components/landding-page/Header.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@core/utils/validations/validations'
import LocalStorageService from '@/@service/utils/LocalStorage.service'
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex'

export default {
  name: 'PasswordReset',
  components: {
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BFormGroup,
    Header,
    BForm,
    BRow,
    BButton,
    CoverImage,
    Footer,
    BFormInput,
    MediaIcon,
    BCardTitle,
    BCardText,
  },

  data() {
    return {

      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
      verificationData: JSON.parse(LocalStorageService.get('verificationData')),

    }
  },

  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

    password: {
      get() {
        return this.$store.state['forgot-password'].passwordReset.password
      },
      set(value) {
        this.$store.commit('forgot-password/setPassword', value)
      },
    },

    confirmPassword: {
      get() {
        return this.$store.state['forgot-password'].passwordReset.confirmPassword
      },
      set(value) {
        this.$store.commit('forgot-password/setConfirmPassword', value)
      },
    },

  },

  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },

    onCancel() {
      LocalStorageService.clear()
      this.$router.push({ name: 'auth-login' })
    },

    ...mapActions('forgot-password', ['submitPasswordReset']),
  },
}
</script>

<style scoped lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
